import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { map, repeat, shareReplay } from 'rxjs/operators';
import type { ActionResultChatSummaryResponse } from '@portal/models/actionResultChatSummaryResponse';
import type { ChatSummaryResponse } from '@portal/models/chatSummaryResponse';
import { wsChatsContext } from '@portal/api-endpoints';

@Injectable({
  providedIn: 'root',
})
export class UnreadMessagesService {
  private readonly chatsSummarySubject = new BehaviorSubject<ChatSummaryResponse>(null);
  public chatsSummary$: Observable<ChatSummaryResponse> = this.chatsSummarySubject.asObservable();
  private readonly MESSAGES_REFRESH_INTERVAL = 60_000;

  constructor(private readonly http: HttpClient) {
    this.setUnreadMessageCounters();
  }

  private setUnreadMessageCounters(): void {
    this.http
      .get<ActionResultChatSummaryResponse>(wsChatsContext.summary)
      .pipe(
        map(res => res.value),
        repeat({ delay: count => (count === 0 ? timer(0) : timer(this.MESSAGES_REFRESH_INTERVAL)) }),
        shareReplay(1),
      )
      .subscribe(this.chatsSummarySubject);
  }

  getUnreadMessagesCount(chatId?: number): Observable<number> {
    return this.chatsSummary$.pipe(map(sum => sum.chatId2ChatInfo[chatId]?.unreadMessages));
  }
}
