const api = '/item-service/api/external';

export const skusContext = {
  skus: `${api}/sku`,
  sku: `${api}/sku/{id}`,
  skuBooking: `${api}/sku/{id}/booking`,
  skuTrack: `${api}/sku/{id}/skuTrack`,
  skuTrackImage: `${api}/skuTrack/{id}/image`,
  updateSkuTrack: `${api}/skuTrack/{id}`,
  updateSkuProduct: `${api}/sku/{id}/changeItem`,
  itemSkus: `${api}/items/{id}/sku`,
  skuDiscounts: `${api}/sku/discount`,
  skuDiscountsBySkuId: `${api}/sku/{id}/discount`,
  skuDiscountById: `${api}/sku/discount/{id}`,
  createWarehouses: `${api}/warehouses`,
  updateWarehouse: `${api}/warehouses/{id}`,
  deleteWarehouse: `${api}/warehouses/{id}`,
  getWarehouses: `${api}/warehouses`,
  updatePackageType: `${api}/packTypes/{id}`,
  createPackType: `${api}/packTypes`,
  deleteItemsSkus: `${api}/items/sku`,
  loadTieredPrices: `${api}/sku/{id}/tieredPrice`,
  updateTieredPrice: `${api}/sku/tieredPrice/{id}`,
  createTieredPrice: `${api}/sku/tieredPrice`,
  digitalArtefacts: `${api}/digitalStore/artefacts`,
  digitalArtefact: `${api}/digitalStore/artefacts/{id}`,
  digitalArtefactTemplate: `${api}/digitalStore/template`,
};
