/**
 * Item service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 *
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { JsonNode } from './jsonNode';

export interface SaveAdditionalFieldRequest {
    title: string;
    name: string;
    jsonSchema: JsonNode;
    required?: boolean;
    entityType: SaveAdditionalFieldRequest.EntityTypeEnum;
    filter?: boolean;
    separator?: boolean;
    sortOrder?: number;
    parentId?: number;
}
export namespace SaveAdditionalFieldRequest {
    export type EntityTypeEnum = 'ITEM' | 'ORDER' | 'USER' | 'COMPANY' | 'FINANCIAL_ITEM' | 'ARTICLE' | 'MERCHANT' | 'ITEM_CATEGORY' | 'COMMON_BUYER' | 'COMMON_SELLER' | 'CATEGORY' | 'TAG' | 'PARTNER' | 'ITEM_PHOTO' | 'BADGE' | 'ORDER_ITEM' | 'LEGAL_BUYER' | 'LEGAL_SELLER' | 'SKU' | 'SELLER_CATEGORY' | 'USER_REGION' | 'ARTICLE_CATEGORY' | 'PARTNER_PRODUCT' | 'FINANCIAL_ITEM_CATEGORY' | 'ORDER_PAYMENT' | 'PROMOCODE' | 'BRAND';
    export const EntityTypeEnum = {
        ITEM: 'ITEM' as EntityTypeEnum,
        ORDER: 'ORDER' as EntityTypeEnum,
        USER: 'USER' as EntityTypeEnum,
        COMPANY: 'COMPANY' as EntityTypeEnum,
        FINANCIALITEM: 'FINANCIAL_ITEM' as EntityTypeEnum,
        ARTICLE: 'ARTICLE' as EntityTypeEnum,
        MERCHANT: 'MERCHANT' as EntityTypeEnum,
        ITEMCATEGORY: 'ITEM_CATEGORY' as EntityTypeEnum,
        COMMONBUYER: 'COMMON_BUYER' as EntityTypeEnum,
        COMMONSELLER: 'COMMON_SELLER' as EntityTypeEnum,
        CATEGORY: 'CATEGORY' as EntityTypeEnum,
        TAG: 'TAG' as EntityTypeEnum,
        PARTNER: 'PARTNER' as EntityTypeEnum,
        ITEMPHOTO: 'ITEM_PHOTO' as EntityTypeEnum,
        BADGE: 'BADGE' as EntityTypeEnum,
        ORDERITEM: 'ORDER_ITEM' as EntityTypeEnum,
        LEGALBUYER: 'LEGAL_BUYER' as EntityTypeEnum,
        LEGALSELLER: 'LEGAL_SELLER' as EntityTypeEnum,
        SKU: 'SKU' as EntityTypeEnum,
        SELLERCATEGORY: 'SELLER_CATEGORY' as EntityTypeEnum,
        USERREGION: 'USER_REGION' as EntityTypeEnum,
        ARTICLECATEGORY: 'ARTICLE_CATEGORY' as EntityTypeEnum,
        PARTNERPRODUCT: 'PARTNER_PRODUCT' as EntityTypeEnum,
        FINANCIALITEMCATEGORY: 'FINANCIAL_ITEM_CATEGORY' as EntityTypeEnum,
        ORDERPAYMENT: 'ORDER_PAYMENT' as EntityTypeEnum,
        PROMOCODE: 'PROMOCODE' as EntityTypeEnum,
        BRAND: 'BRAND' as EntityTypeEnum
    };
}
