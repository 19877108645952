/**
 * Item service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BadgeDetailedShared } from './badgeDetailedShared';
import { CharacteristicWrapperShared } from './characteristicWrapperShared';
import { FileAttachment } from './fileAttachment';
import { Margin } from './margin';
import { PackTypeShared } from './packTypeShared';
import { PaymentPlanShared } from './paymentPlanShared';
import { SimpleImage } from './simpleImage';
import { TieredPriceShared } from './tieredPriceShared';
import { UnitShared } from './unitShared';

export interface SkuItemShared { 
    id?: number;
    paymentPlans?: Array<PaymentPlanShared>;
    skuId?: number;
    categoryId?: number;
    sellerId?: number;
    legalSeller?: boolean;
    vat?: number;
    currency?: SkuItemShared.CurrencyEnum;
    fileAttachment?: FileAttachment;
    label?: string;
    weight?: number;
    characteristicWrapper?: CharacteristicWrapperShared;
    unitShared?: UnitShared;
    price?: number;
    type?: SkuItemShared.TypeEnum;
    margin?: Margin;
    title?: string;
    skuTitle?: string;
    available?: number;
    minOrderSize?: number;
    maxOrderSize?: number;
    photos?: Array<SimpleImage>;
    packType?: PackTypeShared;
    paymentAttrReferenceIds?: Array<number>;
    badges?: Array<BadgeDetailedShared>;
    tieredPrice?: TieredPriceShared;
    category?: string;
    paymentMethods?: Array<SkuItemShared.PaymentMethodsEnum>;
    buyOptions?: { [key: string]: boolean; };
}
export namespace SkuItemShared {
    export type CurrencyEnum = 'RUB' | 'USD' | 'GEL' | 'KZT' | 'AZN' | 'INR' | 'SAR' | 'TMT' | 'XOF' | 'ZAR' | 'CHF' | 'NGN' | 'EGP' | 'KHR' | 'CNY';
    export const CurrencyEnum = {
        RUB: 'RUB' as CurrencyEnum,
        USD: 'USD' as CurrencyEnum,
        GEL: 'GEL' as CurrencyEnum,
        KZT: 'KZT' as CurrencyEnum,
        AZN: 'AZN' as CurrencyEnum,
        INR: 'INR' as CurrencyEnum,
        SAR: 'SAR' as CurrencyEnum,
        TMT: 'TMT' as CurrencyEnum,
        XOF: 'XOF' as CurrencyEnum,
        ZAR: 'ZAR' as CurrencyEnum,
        CHF: 'CHF' as CurrencyEnum,
        NGN: 'NGN' as CurrencyEnum,
        EGP: 'EGP' as CurrencyEnum,
        KHR: 'KHR' as CurrencyEnum,
        CNY: 'CNY' as CurrencyEnum
    };
    export type TypeEnum = 'PHYSICAL' | 'DIGITAL' | 'FINANCIAL_ITEM' | 'TENDER' | 'DIGITAL_CALENDAR' | 'INTERVIEW';
    export const TypeEnum = {
        PHYSICAL: 'PHYSICAL' as TypeEnum,
        DIGITAL: 'DIGITAL' as TypeEnum,
        FINANCIALITEM: 'FINANCIAL_ITEM' as TypeEnum,
        TENDER: 'TENDER' as TypeEnum,
        DIGITALCALENDAR: 'DIGITAL_CALENDAR' as TypeEnum,
        INTERVIEW: 'INTERVIEW' as TypeEnum
    };
    export type PaymentMethodsEnum = 'ONLINE' | 'CASH' | 'INVOICE' | 'EWALLET' | 'CREDIT' | 'FACTORING' | 'POSTPONEMENT' | 'REVERSE_FACTORING';
    export const PaymentMethodsEnum = {
        ONLINE: 'ONLINE' as PaymentMethodsEnum,
        CASH: 'CASH' as PaymentMethodsEnum,
        INVOICE: 'INVOICE' as PaymentMethodsEnum,
        EWALLET: 'EWALLET' as PaymentMethodsEnum,
        CREDIT: 'CREDIT' as PaymentMethodsEnum,
        FACTORING: 'FACTORING' as PaymentMethodsEnum,
        POSTPONEMENT: 'POSTPONEMENT' as PaymentMethodsEnum,
        REVERSEFACTORING: 'REVERSE_FACTORING' as PaymentMethodsEnum
    };
}