/**
 * Order service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { RfqNegotiationShared } from './rfqNegotiationShared';

export interface RequestQuotationShared { 
    id?: number;
    skuId?: number;
    negotiation?: RfqNegotiationShared;
    description?: string;
    buyerId?: number;
    sellerId?: number;
    startPrice?: number;
    quantity?: number;
    status?: RequestQuotationShared.StatusEnum;
    buyerStatus?: RequestQuotationShared.BuyerStatusEnum;
    merchantStatus?: RequestQuotationShared.MerchantStatusEnum;
    orderId?: number;
    chatId?: number;
    createdAt?: Date;
}
export namespace RequestQuotationShared {
    export type StatusEnum = 'ACTIVE' | 'AWAITING_SELLER' | 'AWAITING_BUYER' | 'ACCEPTED' | 'FINISHED' | 'CLOSED';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        AWAITINGSELLER: 'AWAITING_SELLER' as StatusEnum,
        AWAITINGBUYER: 'AWAITING_BUYER' as StatusEnum,
        ACCEPTED: 'ACCEPTED' as StatusEnum,
        FINISHED: 'FINISHED' as StatusEnum,
        CLOSED: 'CLOSED' as StatusEnum
    };
    export type BuyerStatusEnum = 'NEW' | 'VIEW_LATER' | 'CONTACT_TO_CUSTOMER' | 'NOT_INTERESTED';
    export const BuyerStatusEnum = {
        NEW: 'NEW' as BuyerStatusEnum,
        VIEWLATER: 'VIEW_LATER' as BuyerStatusEnum,
        CONTACTTOCUSTOMER: 'CONTACT_TO_CUSTOMER' as BuyerStatusEnum,
        NOTINTERESTED: 'NOT_INTERESTED' as BuyerStatusEnum
    };
    export type MerchantStatusEnum = 'NEW' | 'VIEW_LATER' | 'CONTACT_TO_CUSTOMER' | 'NOT_INTERESTED';
    export const MerchantStatusEnum = {
        NEW: 'NEW' as MerchantStatusEnum,
        VIEWLATER: 'VIEW_LATER' as MerchantStatusEnum,
        CONTACTTOCUSTOMER: 'CONTACT_TO_CUSTOMER' as MerchantStatusEnum,
        NOTINTERESTED: 'NOT_INTERESTED' as MerchantStatusEnum
    };
}