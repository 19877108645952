import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { ActionResultInteger } from '@portal/models/actionResultInteger';
import { ActionResultNegotiationStatisticsResponse } from '@portal/models/actionResultNegotiationStatisticsResponse';
import { ActionResultPageDisputePaginationShared } from '@portal/models/actionResultPageDisputePaginationShared';
import { ActionResultPageRequestQuotationShared } from '@portal/models/actionResultPageRequestQuotationShared';
import { ActionResultReturnsDashboardResponse } from '@portal/models/actionResultReturnsDashboardResponse';
import { ActionResultSellerReviewsPageResponse } from '@portal/models/actionResultSellerReviewsPageResponse';
import { DisputeShared } from '@portal/models/disputeShared';
import { OrderResponse } from '@portal/models/orderResponse';
import { RequestQuotationShared } from '@portal/models/requestQuotationShared';
import {
  disputesContext,
  makerCheckerClientContext,
  negotiationsContext,
  ordersClientContext,
  requestsForQuotationsContext,
  returnsContext,
  reviewsContext,
} from '@portal/api-endpoints';
import { AuthService } from '@portal/auth';
import { PaginatorQuery } from '@portal/components';
import { DEFAULT_HUGE_PAGE_SIZE, DEFAULT_PAGE_NUM, getHttpParams, replaceId } from '@portal/core';
import { UnreadMessagesService } from '../chats';
import DisputeStatusEnum = DisputeShared.DisputeStatusEnum;
import StatusEnum = OrderResponse.StatusEnum;

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly unreadMessagesService: UnreadMessagesService,
  ) {}

  loadChecksCount(): Observable<number> {
    return this.http.get<ActionResultInteger>(makerCheckerClientContext.checksCount).pipe(
      shareReplay(1),
      map(response => response.value),
    );
  }

  loadOrdersCount(): Observable<number> {
    return this.http
      .get<ActionResultInteger>(ordersClientContext.countBadge, {
        params: getHttpParams({ statusList: [StatusEnum.CREATED, StatusEnum.ASSEMBLY] }),
      })
      .pipe(
        shareReplay(1),
        map(response => response.value),
      );
  }

  loadDisputesCount(): Observable<number> {
    return this.http
      .get<ActionResultPageDisputePaginationShared>(disputesContext.disputes, {
        params: getHttpParams({
          status: DisputeStatusEnum.NEW,
          'page.num': DEFAULT_PAGE_NUM,
          'page.size': DEFAULT_PAGE_NUM,
        } as PaginatorQuery),
      })
      .pipe(
        shareReplay(1),
        map((res: ActionResultPageDisputePaginationShared) => res.value.totalCount),
      );
  }

  loadRequestsForQuotationsCount(): Observable<number> {
    return this.http
      .get<ActionResultPageRequestQuotationShared>(requestsForQuotationsContext.all, {
        params: getHttpParams({
          statusList: [RequestQuotationShared.StatusEnum.ACTIVE, RequestQuotationShared.StatusEnum.AWAITINGSELLER],
          'page.num': DEFAULT_PAGE_NUM,
          'page.size': DEFAULT_HUGE_PAGE_SIZE,
        } as PaginatorQuery),
      })
      .pipe(
        shareReplay(1),
        map((res: ActionResultPageDisputePaginationShared) => res.value.totalCount),
      );
  }

  loadNegotiationsCount(): Observable<number> {
    return this.http.get<ActionResultNegotiationStatisticsResponse>(negotiationsContext.statistics).pipe(
      shareReplay(1),
      map(r => r.value?.waitingSellerFeedback),
    );
  }

  loadOpenReturnOrders(): Observable<number> {
    return this.http.get<ActionResultReturnsDashboardResponse>(returnsContext.openBadges).pipe(
      shareReplay(1),
      map(response => response.value?.openReturns ?? 0),
    );
  }

  loadWaitingReviews(): Observable<number> {
    const now = new Date();
    const dayBefore = new Date();
    dayBefore.setDate(now.getDate() - 1);
    dayBefore.setUTCHours(0, 0, 0, 0);

    return this.authService.getUserInfo().pipe(
      switchMap(user =>
        this.http.get<ActionResultSellerReviewsPageResponse>(
          replaceId(reviewsContext.getReviewsBySeller, user?.sellerId || user?.id),
          {
            params: getHttpParams({
              'page.num': DEFAULT_PAGE_NUM,
              'page.size': DEFAULT_PAGE_NUM,
              includeScoreRating: true,
              excludeReviews: true,
              createdAtFrom: dayBefore.toISOString(),
              createdAtTo: now.toISOString(),
            }),
          },
        ),
      ),
      map(res => res?.value?.summaryRating?.waitingSellerResponse),
    );
  }

  loadUnreadMessagesCount(): Observable<number> {
    return this.unreadMessagesService.chatsSummary$.pipe(
      filter(Boolean),
      map(val => Object.values(val.chatType2totalUnreadMessages).reduce((acc, item) => acc + item, 0)),
    );
  }
}
