import { PromocodePaginationRequest } from '@portal/models/promocodePaginationRequest';
import { AppType } from '../..';
import LangEnum = PromocodePaginationRequest.LangEnum;

type LangMap = { [key in AppType]: LangEnum[] };

export enum Locale {
  RU = 'ru',
  EN = 'en-IN',
  FR = 'fr',
  AR = 'ar',
  HI = 'hi',
  KHR = 'khr',
  CNY = 'cny',
}

export const AvailableLangs: LangMap = {
  Safal: [LangEnum.HI, LangEnum.EN],
  STC: [LangEnum.EN],
  Gdesemena: [LangEnum.RU],
  RSHB: [LangEnum.RU],
};
