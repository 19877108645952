/**
 * Item service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * List of order ids
 */
export interface PromocodePaginationRequest { 
    id?: number;
    orderIds?: Array<number>;
    unitedOrderIds?: Array<number>;
    codes?: Array<string>;
    active?: boolean;
    buyerOnlyOnce?: boolean;
    type?: PromocodePaginationRequest.TypeEnum;
    minOrderPrice?: number;
    maxDiscount?: number;
    discount?: number;
    percent?: number;
    hint?: string;
    usageNumber?: number;
    maxUsageNumber?: number;
    usedBudget?: number;
    budget?: number;
    ordersTotal?: number;
    lang?: PromocodePaginationRequest.LangEnum;
    startedAt?: Date;
    finishedAt?: Date;
    beforeDate?: Date;
    afterDate?: Date;
}
export namespace PromocodePaginationRequest {
    export type TypeEnum = 'ABSOLUTE' | 'PERCENT';
    export const TypeEnum = {
        ABSOLUTE: 'ABSOLUTE' as TypeEnum,
        PERCENT: 'PERCENT' as TypeEnum
    };
    export type LangEnum = 'RU' | 'EN' | 'HI' | 'FR' | 'AR' | 'KH' | 'CN';
    export const LangEnum = {
        RU: 'RU' as LangEnum,
        EN: 'EN' as LangEnum,
        HI: 'HI' as LangEnum,
        FR: 'FR' as LangEnum,
        AR: 'AR' as LangEnum,
        KH: 'KH' as LangEnum,
        CN: 'CN' as LangEnum
    };
}